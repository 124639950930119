import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetStdOutDetail } from 'src/app/_Models/AD/ADSTD/ADSTD0303/getStdOutDetail';
import { GetStdOutList } from 'src/app/_Models/AD/ADSTD/ADSTD0303/getStdOutList';
import { GetStdOutListInput } from 'src/app/_Models/AD/ADSTD/ADSTD0303/getStdOutListInput';
import { GetClassList } from 'src/app/_Models/getClassList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { environment } from 'src/assets/environments/environment';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ADSTD0303',
    templateUrl: './ADSTD0303.component.html',
    styleUrls: ['./ADSTD0303.component.css']
})
export class ADSTD0303Component implements OnInit {
    isNewData = false;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    Years!: Array<string>;
    baseUrl = environment.apiUrl;
    modalRef!: BsModalRef;
    initialData = new InitialData(this.http);
    eduList!: GetEduList[];
    deptList!: GetDeptList[];
    classList!: GetClassList[];
    stdOutList!: GetStdOutList[];
    stdOutDetail!: GetStdOutDetail;
    stdOutReasonList!: GetSelectList[];
    pagecount=environment.pagecount;
    stdOutListInput: GetStdOutListInput = {
        OutYear: '',
        OutTerm: '',
        Approve: '',
        EduNo: '',
        DeptNo: '',
        Class: '',
        IDNo: '',
        StdNo: '',
        StdName: '',
        Count: 0,
        Page: 0,
        TotalPage: 0,
        limit: 0
    }
    bsConfig = environment.bsDatePickerConfig;
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private systemService: SystemService,
        private localeService: BsLocaleService,
        private datePipe: DatePipe) {

    }

    ngOnInit(): void {
        this.getStdOutReason();
        this.getYears();
        this.getEduList();
        this.localeService.use('zh-cn');
    }


    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this
                .Years = response;
            }
        });
    }

    getStdOutReason() {
        this.systemService.getStdOutReason().subscribe((response) => {
            this.stdOutReasonList = response;
        })
    }

    getEduList() {
        this.systemService.getEduList('3', '', '', 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.eduList = response;

        }, error => this.toastr.error(error.error))
    }

    getDeptList(edu: string) {
        this.systemService.getDeptList(edu, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.deptList = response;

        }, error => this.toastr.error(error.error));
    }

    getClassList(edu: string, dept: string) {
        this.systemService.getClassList(edu, dept, '3', '', '', 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.classList = response;

        }, error => this.toastr.error(error.error))
    }

    getStdOutList(page:number) {
        this.stdOutListInput.Page=page;
        this.stdOutListInput.limit=environment.pagecount;
        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_03/Get_StdOut_List", this.stdOutListInput).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                this.stdOutList = [];
                return;
            }
            this.stdOutList = response;
            this.stdOutListInput.TotalPage=Math.ceil(response[1].TCount/environment.pagecount);
            this.stdOutListInput.Count=response[1].TCount;
            this.stdOutListInput.Page=this.stdOutListInput.Page;
        })
    }

    getStdOutDetail(dataKey: string, template: TemplateRef<any>, isNewData: boolean) {

        if (!isNewData) {
            this.isNewData = false;
            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_03/Get_StdOut_Detail", { DataKey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdOutDetail = response;

                this.stdOutDetail.StdName = this.stdOutDetail.StdName;

                let modalConfig = {
                    backdrop: true,
                    ignoreBackdropClick: true,
                    class: 'modal-xl'
                };
                this.modalRef = this.modalService.show(template, modalConfig);
            });
        }
        else {

            if (dataKey == this.stdOutDetail.StdNo && this.stdOutDetail.StdName != '') {
                return;
            }

            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_03/Get_StdOut_Detail", { DataKey: dataKey }).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.stdOutDetail = response;
                this.stdOutDetail.StdName = this.stdOutDetail.StdName;
            });
        }

    }

    getStdOutModify() {
        if(this.stdOutDetail.OutDate!=null){
            if(this.stdOutDetail.OutDate!=''){
                this.stdOutDetail.OutDate=this.datePipe.transform(this.stdOutDetail.OutDate, 'yyyy-MM-dd')!.toString();
            }        
        }

        if(this.stdOutDetail.ApproveDate!=null){
            if(this.stdOutDetail.ApproveDate!=''){
                this.stdOutDetail.ApproveDate=this.datePipe.transform(this.stdOutDetail.ApproveDate, 'yyyy-MM-dd')!.toString();
            }        
        }        

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_03/Get_StdOut_Modify", this.stdOutDetail).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("儲存成功");
            this.modalRef.hide();
        })
    }

    getStdOutDelete() {
        Swal.fire({
            title: `確定刪除學生 ${this.stdOutDetail.StdName}`,
            cancelButtonText: "取消",
            showCancelButton: true,
            showConfirmButton: true
        }).then(result => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD03_03/Get_StdOut_Delete", { DataKey: this.stdOutDetail.DataKey }).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功");
                    this.modalRef.hide();
                    this.getStdOutList(1);
                })
            }
        });
    }
    pluspage(){
        if(this.stdOutListInput.Count-(this.stdOutListInput.limit*this.stdOutListInput.Page)>0)
        {
            this.stdOutListInput.Page=this.stdOutListInput.Page+1;
            this.getStdOutList(this.stdOutListInput.Page);
        }
    }
    minuspage(){
        if(this.stdOutListInput.Page>1)
        {
            this.stdOutListInput.Page=this.stdOutListInput.Page-1;
            this.getStdOutList(this.stdOutListInput.Page);
        }
    }
    newData(template: TemplateRef<any>) {
        this.isNewData = true;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };

        this.stdOutDetail = {
            DataKey: '',
            OutYear: '',
            OutTerm: '',
            StdNo: '',
            StdName: '',
            ClassName: '',
            OutNo: '',
            ApplyDate: '',
            Memo: '',
            ApproveDate: '',
            Approve: '',
            RegiName: '',
            CerSchNo: '',
            OutDate:''
        }

        this.modalRef = this.modalService.show(template, modalConfig);
    }
}
