<div class="row mb-5">
    <div class="col-xl-5 bg-light">
        <div class="container p-5 ">
            <form class="form-signin" #loginForm="ngForm" (ngSubmit)="HLLogin()">
                <h1 class="fw-bolder title text-center pt-5">{{schoolName|| ''}}</h1>
                <div class="form-label-group mt-5">
                    <select class="form-select shadow-none bg-light" name="type" [(ngModel)]="model.type">
                        <option value="" selected disabled>--{{'請選擇身份' | translate}}--</option>
                        <option *ngFor="let item of type" value="{{item.ItemValue}}"> {{item.ItemName }} </option>
                        @if (domain.includes('localhost') || domain.includes('school1.hl.com.tw')){
                            <option value="9">整合廠商測試</option>
                        }
                    </select>
                </div>
                <div class="form-label-group mt-3">
                    <input type="text" class="form-control shadow-none bg-light" placeholder="{{'請輸入帳號' | translate}}" name="userAccount" autofocus [(ngModel)]="model.userAccount">
                </div>
                <div class="form-label-group mt-3">
                    <input type="password" class="form-control shadow-none bg-light" placeholder="{{'請輸入密碼' | translate}}" name="userPassword" [(ngModel)]="model.userPassword" autocomplete>
                </div>
                @if (model.type == '9'){
                    <div class="form-label-group mt-3">
                        <input type="text" class="form-control shadow-none bg-light" placeholder="{{'回乎URL' | translate}}" name="callbackURL" [(ngModel)]="model.callbackURL" autocomplete>
                    </div>
                }
                <div class="row mt-3">
                    <div class="col-md-6 mb-3">
                        <div class="form-label-group">
                            <input type="tel" class="form-control shadow-none bg-light" placeholder="{{'請輸入驗證碼' | translate}}" name="validationCode" [(ngModel)]="model.validationCode">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-label-group">
                            <img class="validationImg ms-1" [src]='ImgUrl'>
                            <a class="text-decoration-none pointer ms-3" (click)="getValidationImage()">{{'換一張' | translate}}</a>
                        </div>
                    </div>
                </div>
                <p>{{'驗證碼將於' | translate}} <font>{{sec}}</font> {{'秒後重新產生。' | translate}} </p>
                <input type="hidden" name="Validation_Code_Base64" [(ngModel)]="model.Validation_Code_Base64">
                <div class="row mt-3">
                    <div class="col text-center">
                        <button type="submit" class="other-Button-gray mb-3">{{'登入' | translate}}</button>
                    </div>
                    <div class="col text-center">
                        <button [hidden]="!showFinger" type="button" id="finger" class="other-Button-gray" (click)="fidoLogin(model.userAccount)">
                            <div></div>
                        </button>
                    </div>
                </div>
            </form>
            <div class="text-center">{{'-或-' | translate}}</div>
            <div class="row mt-3">
                <div class="col text-center">
                    <img [hidden]="!showGoogle" id="google" src="../../../assets/Image/btn_google_signin_dark_normal_web@2x.png" width="205" class="pointer" (click)="googleLogin()">
                </div>
            </div>
            <!-- <div class="row mt-3">
                <div class="col text-center">
                    <button [hidden]="!showLine" type="button" id="line" (click)="googleLogin(model.userAccount)">
                        <div class="line-logo"></div><span class="line-text">{{'使用LINE帳號登入' | translate}}</span>
                    </button>
                </div>
            </div> -->
            <select [value]="langu" (change)="changeLanguage($event)" class="form-select shadow-none mt-3 mb-3 bg-light">
                <option value="zh-tw" selected>{{'繁體中文' | translate}}</option>
                <option value="en">English</option>
                <option value="jp">{{'日本語' | translate}}</option>
            </select>
        </div>
    </div>
    <div class="col-xl-7 p-0">
        <img alt="" [src]='ImgUrl1' width="100%">
        <div class="container">
            <div class="row mt-5">
                <div class="col-sm-4 ps-5 pe-5 pt-3" *ngIf="outSideWeb.length != 0">
                    <h4 class="fw-bolder title">{{'校外網站' | translate}}</h4>
                    <div class="list-group" *ngIf="outSideWeb">
                        <a *ngFor="let item of outSideWeb" (click)="openNewTab(item.URL)" class="list-group-item list-group-item-action border-0 fw-bolder list-border pointer">{{item.SiteName}}</a>
                    </div>
                </div>
                <div class="col-sm-8 ps-5 pe-5 pt-3">
                    <h4 class="fw-bolder title" *ngIf="noticeItem.length > 0">{{'注意事項' | translate}}</h4>
                    <div *ngFor="let data of noticeItem; let i = index">
                        <p class="fw-bolder">{{i + 1}}. {{data.ItemName| translate}}</p>
                    </div>
                    <p *ngIf="LineAddURL != ''">
                        <a href="{{LineAddURL}}">
                            <img src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png" height="40" border="0" alt="加入好友">
                        </a>
                    </p>
                    <small class="text-secondary">{{'更新日期' | translate}}：2022-01-31</small><br>
                    <small class="text-secondary">{{'業者' | translate}}：{{'漢龍資訊科技股份有限公司－系統開發' | translate}}</small><br>
                    <small class="text-secondary">{{'地址' | translate}}：{{'台北市南港區園區街3-2號5樓之3' | translate}}</small>
                </div>
            </div>
        </div>
    </div>
</div>
