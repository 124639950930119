import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GetEmergencyTel } from 'src/app/_Models/getEmergencyTel';
import { login } from 'src/app/_Models/login';
import { GetFileList } from 'src/app/_Models/Portal/getFileList';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { FidoService } from 'src/app/_Services/_Shared/fido.service';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { GoogleSSOService } from 'src/app/_Services/_Shared/google-sso.service';
import { LineSsoService } from 'src/app/_Services/_Shared/line-sso.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    baseUrl = environment.apiUrl;
    ImgUrl: SafeUrl = '';
    type = ''
    schoolName!: string;
    loginType!: string;
    modalRef!: BsModalRef;
    fileList!: GetFileList[];
    model: login = {
        type: '',
        userAccount: '',
        userPassword: '',
        validationCode: '',
        Validation_Code_Base64: '',
        SSO: '',
        GoAspx: false,
        isMobile: false,
        callbackURL: ''
    };

    ssoList!: Array<{
        LoginType: string,
        LoginTypeName: string,
        Used: boolean
    }>;
    emergencyTel!: GetEmergencyTel[];
    routerPath = ''
    googleCode: string | null | undefined;
    SSOdata = new FormGroup({
        grant_type: new FormControl(),
        code: new FormControl(),
        redirect_uri: new FormControl(),
        client_id: new FormControl(),
        client_secret: new FormControl(),
    });
    access_token!: string;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private domSanitizer: DomSanitizer,
        private accountService: AccountService,
        private systemService: SystemService,
        private modalService: BsModalService,
        private fidoService: FidoService,
        private googleSSOService: GoogleSSOService,
        private fileExchangeServices: FileExchangeService,
        private lineSsoService: LineSsoService,
        private router: Router, private route: ActivatedRoute
    ) {
        // this.router.events.subscribe((data) => {
        //     if (data instanceof NavigationEnd) {
        //         this.routerPath = data.url.substr(1);
        //         if (this.routerPath.split('=')[1]) {
        //             this.regiLine();
        //         }
        //     }
        // });
    }

    ngOnInit(): void {
        this.getFileList();
        this.getSchool();
        this.getEmergencyTel();
        this.getLoginType();

        //透過檢查回傳是否有state=line
        let IsLine = (this.route.snapshot.queryParamMap.get('state') == "line") ? true : false;

        //抓Google回傳的Code
        this.googleCode = this.route.snapshot.queryParamMap.get('code');

        if (this.googleCode != null) {
            if (IsLine) {
                this.regiLine(this.googleCode);
            }
            else {
                this.InsertGoogleSSOData(this.googleCode);
            }
        }

        this.accountService.currentUser$.subscribe((response) => {

            if (!response) return;

            this.type = response.Type;
            this.model.userAccount = response.Code;
            if (response.Type == '0') {
                this.http.get(this.baseUrl + "Portal/Get_STDCard").subscribe((response: any) => {
                    if (response.Info == false) {
                        this.toastr.error("學生證取得失敗");
                        return;
                    }
                    // 2021.04.14 啟興說Demo先用Message傳Base64，之後再改(?)
                    this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl(response.Message);
                }, error => {
                    this.toastr.error(error.error);
                })
            }
        });
    }

    //取得學校名稱
    getSchool() {
        this.systemService.getSchoolInfo().subscribe((response) => {
            if (!response) {
                this.toastr.error("取得學校名稱失敗!", "系統訊息");
                return;
            }
            this.schoolName = response.Sch_Name;
        });
    }


    setAuth(type: string, template: TemplateRef<any>) {
        let deviceKey = sessionStorage.getItem('DeviceKey');
        if (!deviceKey) {
            this.fidoService.getDeviceKey().subscribe(response => {
                if (response.Info == false) {
                    Swal.fire(response.Message);
                    return;
                }
                sessionStorage.setItem('DeviceKey', response.DeviceKey);
            });
        }

        this.http.post<any>(this.baseUrl + "Profile/Check_RegiAccount", {
            Code: this.model.userAccount,
            DeviceKey: deviceKey,
            Verify: type
        }).subscribe((response) => {
            if (response.Info == true) {
                Swal.fire("此帳號已有註冊紀錄");
                return;
            }

            //判斷為line的時候，改為超連結
            if (type == 'L') {
                this.lineSsoService.lineApiSource$.subscribe(response => {
                    window.location.href = (`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${response.LoginChannelID}&redirect_uri=${response.LoginCallbackURL}&state=line&scope=openid%20profile%20email`)
                });
                return;
            }

            this.openModal(type, template);
        });
    }

    //開啟驗證密碼視窗
    openModal(type: string, template: TemplateRef<any>) {
        this.loginType = type;
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-md'
        };
        this.modalRef = this.modalService.show(template, modalConfig);
    }

    //綁定前驗證密碼是否正確
    checkUserAccount(pwd: string) {
        this.model.userPassword = pwd;
        this.http.post<any>(this.baseUrl + "Login/Check_UserAccount", this.model).subscribe((response) => {
            if (response.Info == false) {
                Swal.fire(response.Message);
                this.modalRef.hide();
                return;
            }

            //判斷是否為指紋辨識
            if (this.loginType == 'finger') {
                this.requestReg(this.model.userAccount);
            } else {
                this.setGoogleAccount();
            }

            this.modalRef.hide();
        });
    }

    requestReg(code: string) {
        let deviceKey = sessionStorage.getItem('DeviceKey');
        if (!deviceKey) {
            Swal.fire("裝置碼取得錯誤，請重新操作");
            this.modalRef.hide();
            return;
        }
        else {
            this.fidoService.requestReg(code, deviceKey).subscribe((response) => {
                if (response.Info == false) {
                    Swal.fire({
                        title: "註冊失敗",
                        html: `<p>${response.Message}</p>`
                    });
                    return;
                }
                this.doReg(code, JSON.parse(response.Message));
            })
        }
    }

    doReg(code: string, objJson: any) {
        var makeCredReq = this.fidoService.preformatMakeCredReq(objJson.body);
        navigator.credentials.create({ "publicKey": makeCredReq }).then((makeCredChallenge) => {
            var newCredentialInfo = this.fidoService.publicKeyCredentialToJSON(makeCredChallenge);
            var fido2DoRegReq = {
                header: {
                    appVersion: "v1",
                    channelCode: "hi.fido2.poc",
                    deviceBrand: "google",
                    deviceType: "chrome",
                    deviceUuid: "xxx",
                    deviceVersion: "92.0.4515.107",
                    userIp: "localhost"
                },
                body: {}
            };
            fido2DoRegReq.body = { regPublicKeyCredential: newCredentialInfo };
            let deviceKey = sessionStorage.getItem('DeviceKey');
            this.fidoService.regiFIDO({
                Code: code,
                DeviceKey: deviceKey,
                PublicKey: JSON.stringify(fido2DoRegReq),
                Model: "doReg"
            }).subscribe((response) => {
                if (response.Info == false) {
                    Swal.fire(response.Message);
                    return;
                }
                Swal.fire("註冊完成");
            })
        });
    }

    //個人檔案
    getFileList() {
        this.http.get<any>(this.baseUrl + "Profile/Get_FileList").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.fileList = response;
        })
    }

    //下載檔案
    getFileDownload(URL: string, FileName: string) {
        this.fileExchangeServices.ExportFile("Profile/Get_FileDownload", { DataKey: URL, IP: '' }).subscribe((response) => {
            let url = window.URL.createObjectURL(response.blob);
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        });
    }

    //緊急連絡電話
    getEmergencyTel() {
        this.http.get<any>(this.baseUrl + "Portal/Get_EmergencyTel").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.emergencyTel = response;
        });
    }

    //取得可用SSO種類
    getLoginType() {
        this.http.get<any>(this.baseUrl + "Login/Get_LoginType").subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.ssoList = response;
        });
    }

    ////登入Google
    setGoogleAccount() {
        this.googleSSOService.GetAuthGoogleURL("Register");
    }

    //綁定Google
    InsertGoogleSSOData(GoogleCode: string): void {
        this.http.post<any>(this.baseUrl + "GoogleLogin/GoogleIDRegister", { GoogleCode: GoogleCode }).subscribe((response) => {
            if (response.Info == false) {
                Swal.fire(response.Message);
                return;
            }
            this.toastr.success("綁定成功!");
            this.getLoginType();
        });
    }

    //綁定line
    regiLine(code: string) {
        this.lineSsoService.regiLine(code).subscribe((response) => {

            if (response.Info == false) {
                this.toastr.warning("Line綁定失敗");
                return;
            }
            this.toastr.success("綁定成功");
            this.router.navigateByUrl("profile");
        });
    }

    //解除綁定
    getLoginTypeRemove(LoginType: string) {
        this.http.post<any>(this.baseUrl + "Login/Get_LoginType_Remove", { LoginType: LoginType }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.toastr.success("解除綁定成功");
            this.getLoginType();
        });
    }
}
