<div style="width: 80%; margin: auto">
    <ng-container>
        <app-ADSTD-search-input (childEvent)="setModel($event)"></app-ADSTD-search-input>
    </ng-container>
    <div class="row">
        <div class="col-md-4">
            <button class="search-btn me-3 float-start" (click)="getStdList(1)">查詢</button>
            <button class="other-Button-gray me-3 float-start" (click)="setHideForm()" [hidden]="hideForm">隱藏條件</button>
            <button class="other-Button-gray me-3 float-start" (click)="setHideForm()" [hidden]="!hideForm">顯示條件</button>
        </div>
        <div class="col-md-8">
            <button class="other-Button-gray me-3 float-start" *ngIf="open==true && isFire==false" (click)="saveStdData(true)">新增</button>
            <button class="other-Button-gray me-3 float-start" *ngIf="open==false && isFire==false" (click)="saveStdData(false)">儲存</button>
            <button class="other-Button-gray me-3 float-start" *ngIf="open==false && isFire==false" (click)="deleteStdData()">刪除</button>
            <button class="other-Button-gray me-3 float-start" *ngIf="open==false && isFire==false" (click)="fireData(FireTemplate)">開除</button>
            <button class="other-Button-gray me-3 float-start" (click)="clearData()">清除</button>
        </div>
    </div>
    <hr class="mt-3">
    <div class="row mb-3">
        <div class="col-md-3" style="height: 500px; overflow-y: scroll;">
            <table class="table table-sm table-hover text-center table-bordered">
                <thead class="thead-dark-red">
                    <tr>
                        <th>學號</th>
                        <th>姓名</th>
                        <th>班級</th>
                    </tr>
                </thead>
                <tbody>
                    <tr [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" class="pointer" *ngFor="let item of stdList; let i = index" (click)="getStdData(item.DataKey, i)">
                        <td>{{item.StdNo}}</td>
                        <td>{{item.StdName}}</td>
                        <td>{{item.ClassName}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="col-md-12">
                <p>每頁{{pagecount}}筆 共{{this.getStdListInput.Count}}筆資料    目前在{{this.getStdListInput.Page}}/{{this.getStdListInput.TotalPage}}頁</p>
                <button class="other-Button-gray float-start" (click)="minuspage()">上一頁</button><button class="other-Button-gray float-start" (click)="pluspage()">下一頁</button>
            </div>
        </div>
        <div class="col-md-9" style="height: 500px; overflow-y: scroll;">
            <tabset #tabs>
                <tab heading="基本資料">
                    <ng-container>
                        <div class="row" *ngIf="isFire==true">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">開除學年</span>
                                        <select class="form-select shadow-none" [(ngModel)]="stdData.FireYear">
                                            <option value="{{item}}" *ngFor="let item of Years"> {{item}}學年 </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">開除學期</span>
                                        <select class="form-select shadow-none" [(ngModel)]="stdData.FireTerm">
                                            <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                                        </select>
                                    </div>
                                </div>          
                                <div class="col-md-4">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">開除日期</span>
                                        <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                                            [bsConfig]="bsConfig" readonly [(ngModel)]="stdData.FireDate"
                                            style="background-color: transparent;">
                                    </div>
                                </div>  
                            </div>
                            <div class="row">        
                                <div class="col-md-12">
                                    <div class="input-group mb-12">
                                        <span class="input-group-text">開除文號</span>
                                        <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.FireMoe">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="buttonRowH40">
                                    <button class="save-btn-gray float-end me-3 mx-auto" (click)="saveStdData(false)">儲存</button>
                                </div>
                            </div>  
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">學制</span>
                                    <select class="form-select shadow-none" #stdEdu (change)="getDeptList()" [(ngModel)]="stdData.EduNo">
                                        <option value="" selected disabled>--請選擇學制--</option>
                                        <option *ngFor="let item of stdEduList" [ngValue]="item.Edu_No"> {{item.Edu_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">系所</span>
                                    <select class="form-select shadow-none" (ngModelChange)="getClassList(stdData.EduNo, $event, false); getTeamList($event, false)" [(ngModel)]="stdData.DeptNo">
                                        <option value="" selected disabled>--請選擇系所--</option>
                                        <option *ngFor="let item of stdDeptList" [ngValue]="item.Dept_No"> {{item.Dept_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">組別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.TeamNo">
                                        <option value="" selected>--請選擇組別--</option>
                                        <option *ngFor="let item of stdTeamList" ngValue="{{item.Team_No}}"> {{item.Team_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">年級</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.Grade">
                                        <option value="" selected>--請選擇年級--</option>
                                        <option *ngFor="let item of grade" value="{{item.itemValue}}">{{item.itemName}} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">班別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.Class">
                                        <option value="" disabled selected>--請選擇班別--</option>
                                        <option *ngFor="let item of stdClassList" ngValue="{{item.Class}}"> {{item.Class_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">學生狀態</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.Status">
                                        <option value="" disabled selected>--請選擇--</option>
                                        <option *ngFor="let item of sTDStatusList" ngValue="{{item.Status_No}}"> {{item.Status_Name}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap">
                                        <span class="input-group-text">學號</span>
                                    <input type="text" [readonly]="IsEdit" class="form-control shadow-none" [(ngModel)]="stdData.StdNo">
                                </div>
                            </div>                           
                            <div class="col-md-3" [hidden]="true">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">僧/俗</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.MonkType">
                                        <option value="0" disabled selected>無</option>
                                        <option value="1">僧</option>
                                        <option value="2">俗</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3" [hidden]="true">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">法名</span>
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.StdMonkName">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <hr class="border-primary">
                            <div class="col-md-3 mb-3">
                                <figure class="figure">
                                    <img [src]="ImgUrl" class="figure-img img-fluid rounded" />
                                    <figcaption class="figure-caption text-center">
                                        <button class="other-Button-blue img-upload" (click)="importInputCkick()">照片維護</button>
                                        <input type="file" style="display: none;" id="img" (change)="importData($event)" accept=".png">
                                    </figcaption>
                                </figure>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">中文名</span>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.StdName">
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">性別</span>
                                            <select class="form-select shadow-none" [(ngModel)]="stdData.Sex">
                                                <option value="" disabled selected>請選擇</option>
                                                <option value="男">男</option>
                                                <option value="女">女</option>
                                            </select>
                                        </div>
                                    </div>                                    
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">出生日</span>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.Birthday">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">身分證</span>
                                            <input type="text" maxlength="15" class="form-control shadow-none" [(ngModel)]="stdData.IDNo">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-3">
                                        <button class="other-Button-blue img-upload" (click)="SaveMaster()">儲存</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-8 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">英文名</span>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.StdEngName">
                                        </div>
                                    </div>                                    
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">英文別名</span>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.AliasEng">
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group mb-3">
                                                <label class="input-group-text">國籍</label>
                                            <select class="form-select shadow-none" [(ngModel)]="stdData.CountryID">
                                                <option selected disabled value="">請選擇</option>
                                                <option *ngFor="let item of countryList" ngValue="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group mb-3">
                                                <label class="input-group-text">僑居地</label>
                                            <select class="form-select shadow-none" [(ngModel)]="stdData.AbroadID">
                                                <option selected disabled value="">請選擇</option>
                                                <option *ngFor="let item of countryList" ngValue="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                            </select>
                                        </div>
                                    </div>                                    
                                    <div class="col-12 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">護照碼</span>
                                            <input [readonly]="IsEdit" type="text" maxlength="15" class="form-control shadow-none" [(ngModel)]="stdData.PassPortNo">
                                        </div>
                                    </div>                                   
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="input-group flex-nowrap mb-3">
                                                <span class="input-group-text">個人信箱</span>
                                            <input type="email" class="form-control shadow-none" placeholder="example@gmail.com" [(ngModel)]="stdData.OtherEmail">
                                        </div>
                                    </div>
                                </div>                                
                                <div class="row" [hidden]="true">
                                    <div class="col-6 col-md-4 mb-3">
                                        <div class="input-group flex-nowrap">
                                                <span class="input-group-text">血型</span>
                                            <select class="form-select shadow-none" [(ngModel)]="stdData.Blood">
                                                <option value="" disabled>請選擇</option>
                                                <option *ngFor="let item of bloodType" value="{{item.itemValue}}"> {{item.itemName}} </option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div class="col-md">
                                        <div class="input-group mb-3">
                                                <label class="input-group-text">婚姻狀況</label>
                                            <select class="form-select shadow-none" [(ngModel)]="stdData.Marry">
                                                <option selected disabled value="">請選擇</option>
                                                <option *ngFor="let item of marriage" ngValue="{{item.itemValue}}"> {{item.itemName}} </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="input-group mb-3">
                                                <label class="input-group-text">兵役狀況</label>
                                            <select class="form-select shadow-none" [(ngModel)]="stdData.MilitaryService">
                                                <option disabled selected value="">請選擇</option>
                                                <option *ngFor="let item of army" ngValue="{{item.itemValue}}"> {{item.itemName}} </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="border-primary">
                        <label>戶籍地址：</label>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap">
                                        <span class="input-group-text">郵遞區號</span>
                                    <input type="text" maxlength="6" placeholder="3+2 或 3+3" class="form-control shadow-none" [(ngModel)]="stdData.NomZip">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                        <label class="input-group-text">縣市</label>
                                    <select class="form-select shadow-none" (ngModelChange)="getTownList($event, false)" [(ngModel)]="stdData.NomCity">
                                        <option selected value="">請選擇</option>
                                        <option *ngFor="let item of cityList" [ngValue]="item.ItemValue"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                        <label class="input-group-text">區域</label>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.NomTown">
                                        <option selected value="">請選擇</option>
                                        <option *ngFor="let item of nomTownList" ngValue="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group mb-2">
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.VillageName">
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.VillageType">
                                        <option selected value="">請選擇</option>
                                        <option value="里">里</option>
                                        <option value="村">村</option>
                                    </select>   
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.NeighborHood">
                                        <label class="input-group-text">鄰</label>
                                </div>
                            </div>                            
                            <div class="col-md-10">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control shadow-none" placeholder="地址剩下部分請於此填寫，請勿再從頭寫一次" [(ngModel)]="stdData.NomElseAddr">
                                </div>
                            </div>
                        </div>
                        <label>通訊地址：</label>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap">
                                        <span class="input-group-text">郵遞區號</span>
                                    <input type="text" maxlength="6" placeholder="3+2 或 3+3" class="form-control shadow-none" [(ngModel)]="stdData.ComZip">
                                </div>
                            </div>                            
                            <div class="col-md-9">
                                <div class="input-group mb-3">
                                    <input type="address" class="form-control shadow-none" placeholder="請填寫完整地址" [(ngModel)]="stdData.ComAddr">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md">
                                <div class="input-group mb-3">
                                        <label class="input-group-text">戶籍電話</label>
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.NomTel">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="input-group mb-3">
                                        <label class="input-group-text">通訊電話</label>
                                    <input type="tel" class="form-control shadow-none" [(ngModel)]="stdData.ComTel">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="input-group mb-3">
                                        <label class="input-group-text">行動電話</label>
                                    <input type="tel" class="form-control shadow-none" [(ngModel)]="stdData.MobileTel">
                                </div>
                            </div>
                        </div>
                        <hr class="border-primary">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">學生類別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryID">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of entryIDList" ngValue="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">編級狀態</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.ClassStatus">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option value="0">一般生</option>
                                        <option value="1">提編生</option>
                                        <option value="2">降級生</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">原住民別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.FieldType">
                                        <option value="">--請選擇--</option>
                                        <option *ngFor="let item of fileType" ngValue="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學類別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryDuct">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of entryDuctList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學身份</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryNo">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of entryNewList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">特殊類別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.SpecialType">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of entrySpecialTypeList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">公自費別</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.ExpenseType">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option value="公">公費生</option>
                                        <option value="自">自費生</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學區分</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryDiv">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of entryDivList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>                            
                            <div class="col-md-4">
                                <div class="row">
                                    <div class="form-check">
                                        <input class="form-check-input pointer" type="checkbox" id="check1" [checked]="stdData.IsSameDept == 'Y'" (click)="setChecked('IsSameDept', $event)">
                                        <label class="form-check-label pointer" for="check1">是否與大學部同系所?</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input pointer" type="checkbox" id="check2" [checked]="stdData.IsAddition == '1'" (click)="setChecked('IsAddition', $event)">
                                        <label class="form-check-label pointer" for="check2">是否為額外錄取?</label>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <hr class="border-primary">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學學年</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.InYear">
                                        <option value="" selected disabled>請選擇</option>
                                        <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學學期</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.InTerm">
                                        <option value="" selected disabled>請選擇</option>
                                        <option *ngFor="let item of term" [value]="item.itemValue"> {{item.itemName}} </option>
                                    </select>
                                </div>
                            </div>                            
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學日</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryYear">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                                    </select>
                                        <span class="input-group-text">年</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryMonth">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of month" value="{{item.itemValue}}">{{item.itemName}} </option>
                                    </select>
                                        <span class="input-group-text">月</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryDay">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of day" value="{{item.itemValue}}">{{item.itemName}} </option>
                                    </select>
                                        <span class="input-group-text">日</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">入學核准文號</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EntryMoeNo">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of entryMoeNoList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>                            
                            <div class="col-md-4">
                                <div class="form-check">
                                    <input class="form-check-input pointer" type="checkbox" id="check3" [checked]="stdData.InStatus == 'Y'" (click)="setChecked('Instatus', $event)">
                                    <label class="form-check-label pointer" for="check3">是否提早入學?</label>
                                </div>
                            </div>
                        </div>
                        <hr class="border-primary">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">畢業學年</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EndYear">
                                        <option value="" selected disabled>請選擇</option>
                                        <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">畢業學期</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.EndTerm">
                                        <option value="" selected disabled>請選擇</option>
                                        <option *ngFor="let item of term" [value]="item.itemValue">{{item.itemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">畢業日期</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.GradYear">
                                        <option value="" selected disabled>請選擇</option>
                                        <option *ngFor="let item of Years" [value]="item">{{item}}</option>
                                    </select>
                                        <span class="input-group-text">年</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.GradMonth">
                                        <option value="" selected disabled>請選擇</option>
                                        <option *ngFor="let item of month" value="{{item.itemValue}}">{{item.itemName}} </option>
                                    </select>
                                        <span class="input-group-text">月</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">畢業核准文號</span>
                                    <select class="form-select shadow-none" [(ngModel)]="stdData.GradMoeNo">
                                        <option value="" selected disabled>--請選擇--</option>
                                        <option *ngFor="let item of gradMoeNoList" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">畢業證書編號</span>
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.GradSchNo">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-check">
                                    <input class="form-check-input pointer" type="checkbox" id="check4" [checked]="stdData.GradStatus == '1'" (click)="setChecked('GradStatus', $event)">
                                    <label class="form-check-labe pointer" for="check4">是否提早畢業?</label>
                                </div>
                            </div>
                        </div>
                        <hr class="border-primary">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-check">
                                    <input class="form-check-input pointer" type="checkbox" id="check5" [(ngModel)]="stdData.Form5Student">
                                    <label class="form-check-labe pointer" for="check5">是否為中五生？</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">推薦單位</span>
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.RecomUnit">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">交換學校</span>
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.ExchangeSchool">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">獎學金名稱</span>
                                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdData.ScholarshipName">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-group flex-nowrap mb-3">
                                        <span class="input-group-text">獎學金金額</span>
                                    <input type="number" class="form-control shadow-none" [(ngModel)]="stdData.ScholarshipMoney">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </tab>
                <tab heading="學歷資料">
                    <div style="overflow-x: scroll;">
                        <ng-container *ngIf="stdData">
                            <button class='other-Button-gray mt-3 mb-3 me-3' style="width: 80px;" (click)="deleteData('school')">刪除最後一筆</button>
                            <button class='other-Button-gray mt-3 mb-3' (click)="addData('school')">新增一筆</button>
                            <table class="table table-hover text-center" *ngIf="stdData.STD02 else nothing" style="width: 1400px;">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th>學校類別</th>
                                        <th>學校名稱</th>
                                        <th>科系名稱</th>
                                        <th>畢業年</th>
                                        <th>畢業月</th>
                                        <th>畢(肄)業</th>
                                        <th>最高學歷</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of stdData.STD02; let i = index">
                                        <td>
                                            <select class="form-select shadow-none" [(ngModel)]="item.SchClass">
                                                <option selected disabled value="">請選擇</option>
                                                <option *ngFor="let item of schoolTypeList" value="{{item.SchClass}}"> {{item.SchClass_Name}} </option>
                                            </select>
                                        </td>
                                        <td>
                                            <div class="input-group flex-nowrap mb-3">
                                                <input type="text" class="form-control shadow-none" [(ngModel)]="item.Sch_Name" disabled>
                                                    <span class="input-group-text pointer" (click)="openModel('sch' ,schTemplate, i)">...</span>
                                            </div>
                                        </td>
                                        <td>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Dept_Name">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Grad_Year">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control shadow-none" [(ngModel)]="item.Grad_Month">
                                        </td>
                                        <td>
                                            <select class="form-select shadow-none" [(ngModel)]="item.Grad_Type">
                                                <option selected disabled value="">請選擇</option>
                                                <option *ngFor="let item of graduateType" value="{{item.ItemValue}}"> {{item.ItemName}} </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="form-select shadow-none" [(ngModel)]="item.Mark">
                                                <option value=""></option>
                                                <option value="*">*</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                    </div>
                </tab>
                <tab heading="家庭資料">
                    <div style="overflow-x: scroll;">
                        <ng-container *ngIf="stdData">
                            <button class='other-Button-gray mt-3 mb-3 me-3' style="width: 150px;" (click)="deleteData('family')">刪除最後一筆</button>
                            <button class='other-Button-gray mt-3 mb-3' (click)="addData('family')">新增一筆</button>
                            <table class="table table-hover text-center" *ngIf="stdData.STD03 else nothing" style="width: 1500px;">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th>監護人</th>                                        
                                        <th>稱謂</th>
                                        <th>姓名</th>
                                        <th>聯絡電話</th>
                                        <th>郵遞區號</th>
                                        <th>聯絡地址</th>
                                        <th>EMail</th>
                                        <th>身分證號</th>                                        
                                        <th>出生日期</th>
                                        <th>職業</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of stdData.STD03">
                                        <td>
                                            <select class="form-select shadow-none" [(ngModel)]="item.Mark">
                                                <option value="">否</option>
                                                <option value="*">是</option>
                                            </select>
                                        </td>                        
                                        <td style="width: 80px;">
                                            <select class="form-select shadow-none" [(ngModel)]="item.Rel_No">
                                                <option value="" disabled selected>請選擇</option>
                                                <option *ngFor="let item of relNameList" value="{{item.Rel_No}}"> {{item.Rel_Name}} </option>
                                            </select>
                                        </td>
                                        <td><input type="text" [(ngModel)]="item.Dep_Name" class="form-control shadow-none">
                                        </td>
                                        <td><input type="text" [(ngModel)]="item.Com_Tel" class="form-control shadow-none">
                                        </td>
                                        <td><input type="text" [(ngModel)]="item.Com_Zip" class="form-control shadow-none" placeholder="3+2或3+3">
                                        </td>
                                        <td><input type="text" [(ngModel)]="item.Com_Address" class="form-control shadow-none"></td>
                                        <td><input type="text" [(ngModel)]="item.EMail" class="form-control shadow-none"></td>                                        
                                        <td><input type="text" [(ngModel)]="item.Dep_ID" class="form-control shadow-none">
                                        </td>
                                        <td><input type="date" [(ngModel)]="item.Birthday" class="form-control shadow-none">
                                        </td>
                                        <td><input type="text" [(ngModel)]="item.Occ_No" class="form-control shadow-none">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                    </div>
                </tab>
                <tab heading="經歷資料">
                    <ng-container *ngIf="stdData">
                        <table class="table table-hover text-center mt-3" *ngIf="stdData.STD04 else nothing">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th>服務機關</th>
                                    <th>職務名稱</th>
                                    <th>起始年</th>
                                    <th>起始月</th>
                                    <th>終止年</th>
                                    <th>終止月</th>
                                    <th>備註</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of stdData.STD04">
                                    <td><input type="text" [(ngModel)]="item.Company" class="form-control shadow-none">
                                    </td>
                                    <td><input type="text" [(ngModel)]="item.Duty" class="form-control shadow-none">
                                    </td>
                                    <td><input type="text" [(ngModel)]="item.Start_Year" class="form-control shadow-none"></td>
                                    <td><input type="text" [(ngModel)]="item.Start_Month" class="form-control shadow-none"></td>
                                    <td><input type="text" [(ngModel)]="item.End_Year" class="form-control shadow-none">
                                    </td>
                                    <td><input type="text" [(ngModel)]="item.End_Month" class="form-control shadow-none"></td>
                                    <td><input type="text" [(ngModel)]="item.Memo" class="form-control shadow-none">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </tab>
                <tab heading="註冊資料">
                    <ng-container *ngIf="stdData">
                        <table class="table table-hover text-center mt-3" *ngIf="stdData.STD05 else nothing">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th>註冊學年</th>
                                    <th>註冊學期</th>
                                    <th>註冊班級</th>
                                    <th>總修業年限</th>
                                    <th>已修業年限</th>
                                    <th>可修業年限</th>
                                    <th>註冊日期</th>
                                    <th>完成註冊</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of stdData.STD05">
                                    <td>{{item.Regi_Year}}</td>
                                    <td>{{item.Regi_Term}}</td>
                                    <td>{{item.Class_Name}}</td>
                                    <td>{{item.Total_Term}}</td>
                                    <td>{{item.Already_Term}}</td>
                                    <td>{{item.Remain_Term}}</td>
                                    <td>{{item.Regi_Date}}</td>
                                    <td [ngClass]="{'text-success': item.Result_Log == 'Y', 'text-danger': item.Result_Log == ''}"> {{item.Result_Log == 'Y'? '已註冊' : '未註冊'}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </tab>
                <tab heading="輔系雙主修">
                    <ng-container *ngIf="stdData">
                        <table class="table table-hover text-center mt-3" *ngIf="stdData.STD09 else nothing">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th>修讀學年</th>
                                    <th>修讀學期</th>
                                    <th>修讀類型</th>
                                    <th>加修科系</th>
                                    <th>核示狀態</th>
                                    <th>放棄日期</th>
                                    <th>修讀結果</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of stdData.STD09">
                                    <td>{{item.Add_Year}}</td>
                                    <td>{{item.Add_Term}}</td>
                                    <td>{{item.Add_Type_Name}}</td>
                                    <td>{{item.Dept_Name}}</td>
                                    <td class="fw-bolder" [ngClass]="{'text-success' : item.Approve == 'Y',
                                                    'text-danger' : item.Approve == 'N'}"> {{item.Approve == 'Y'? '已通過' : '不通過'}} </td>
                                    <td>{{item.Give_Date}}</td>
                                    <td class="fw-bolder" [ngClass]="{'text-warning' : item.Result_Log == 'Y',
                                                    'text-danger' : item.Result_Log == 'N',
                                                    'text-success' : item.Result_Log == 'F'}"> {{item.Result_Log == 'Y'? '進修中' : item.Result_Log == 'N'? '放棄' : '完成'}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </tab>
                <tab heading="轉系資料">
                    <div style="overflow-x: scroll;">
                        <ng-container *ngIf="stdData">
                            <table class="table table-hover text-center mt-3" *ngIf="stdData.STD08 else nothing" style="width: 1500px;">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th>轉入學年</th>
                                        <th>轉入學期</th>
                                        <th>申請原因</th>
                                        <th>核示狀態</th>
                                        <th>核示日期</th>
                                        <th>原屬科系</th>
                                        <th>原屬班級</th>
                                        <th>轉入科系</th>
                                        <th>轉入班級</th>
                                        <th>降轉狀態</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of stdData.STD08">
                                        <td>{{item.Turn_Year}}</td>
                                        <td>{{item.Turn_Term}}</td>
                                        <td>{{item.Turn_Name}}</td>
                                        <td>{{item.Approve == 'Y'? '是' : '否'}}</td>
                                        <td>{{item.Approve_Date}}</td>
                                        <td>{{item.Old_Dept_Name}}</td>
                                        <td>{{item.Old_Class_Name}}</td>
                                        <td>{{item.Dept_Name}}</td>
                                        <td>{{item.Class_Name}}</td>
                                        <td>{{item.DownMark == 'Y'? '是' : '否'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                    </div>
                </tab>
                <tab heading="休復學資料">
                    <div style="overflow-x: scroll;">
                        <ng-container *ngIf="stdData">
                            <table class="table table-hover text-center mt-3" *ngIf="stdData.STD06 else nothing" style="width: 2000px;">
                                <thead class="thead-dark-red">
                                    <tr>
                                        <th>休學學年期</th>
                                        <th>休學學期數</th>
                                        <th>應復學年期</th>
                                        <th>原因</th>
                                        <th>休學班級</th>
                                        <th>復學班級</th>
                                        <th>本人取消休學</th>
                                        <th>休學核准</th>
                                        <th>休學核准日</th>
                                        <th>休學字號</th>
                                        <th>是否復學</th>
                                        <th>是否延長</th>
                                        <th>復學學年期</th>
                                        <th>本人取消復學</th>
                                        <th>復學核准</th>
                                        <th>復學核准日</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of stdData.STD06">
                                        <td>{{item.Rest_Year}}-{{item.Rest_Term}}</td>
                                        <td>{{item.TermCounts}}</td>
                                        <td>{{item.End_Year}}-{{item.End_Term}}</td>
                                        <td>{{item.Rest_Name}}</td>
                                        <td>{{item.Old_Class}}</td>
                                        <td>{{item.Class}}</td>
                                        <td>{{item.Cancel}}</td>
                                        <td>{{item.Approve}}</td>
                                        <td>{{item.Apply_Date}}</td>
                                        <td>{{item.Moe_No}}</td>
                                        <td>{{item.Recover}}</td>
                                        <td>{{item.Delay}}</td>
                                        <td>{{item.Recover_Year}}-{{item.Recover_Term}}</td>
                                        <td>{{item.Cancel_2}}</td>
                                        <td>{{item.Approve_2}}</td>
                                        <td>{{item.Approve_Date_2}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-container>
                    </div>
                </tab>
                <tab heading="退學資料">
                    <ng-container *ngIf="stdData">
                        <table class="table table-hover text-center mt-3" *ngIf="stdData.STD07 else nothing">
                            <thead class="thead-dark-red">
                                <tr>
                                    <th>退學學年期</th>
                                    <th>退學原因</th>
                                    <th>本人取消</th>
                                    <th>核准</th>
                                    <th>核准日</th>
                                    <th>核准人</th>
                                    <th>休業證書字號</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of stdData.STD07">
                                    <td>{{item.Out_Year}}-{{item.Out_Term}}</td>
                                    <td>{{item.Out_Name}}</td>
                                    <td>{{item.Cancel}}</td>
                                    <td>{{item.Approve}}</td>
                                    <td>{{item.Approve_Date}}</td>
                                    <td>{{item.Recover_Name}}</td>
                                    <td>{{item.Cer_Sch_No}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                </tab>
            </tabset>
        </div>
    </div>
</div>

<ng-template #nothing>
    <h2 class="text-center mt-3 text-danger fw-bolder">暫時沒有資料</h2>
</ng-template>

<ng-template #schTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">查詢學校</h4>
        <button type="button" class="btn-close btn-close-white pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">學校類別</span>
                    <select class="form-select shadow-none" #SchClass>
                        <option value="">--請選擇--</option>
                        <option value="{{item.SchClass}}" *ngFor="let item of schoolTypeList"> {{item.SchClass_Name}} </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">查詢代碼或關鍵字</span>
                    <input type="text" class="form-control shadow-none" #Search_Word>
                </div>
            </div>
            <div class="col-md-3">
                <button class="search-btn float-end me-3 mb-3" (click)="search(SchClass.value, Search_Word.value)">查詢</button>
            </div>
        </div>
        <div style="height: 500px; overflow-y: scroll;">
            <table class="table table-hover table-striped text-center">
                <thead class="thead-dark-red">
                    <tr>
                        <th style="width: 60px;"></th>
                        <th>學校類別</th>
                        <th>學校代碼</th>
                        <th>學校名稱</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index" class="pointer" [ngClass]="{'':item.Selected == false, 'row-selected-bg':item.Selected == true}" (click)="setSchool(item.Sch_Name)">
                        <td style="width: 60px;">{{i + 1}}</td>
                        <td>{{item.SchClass_Name}}</td>
                        <td>{{item.Sch_No}}</td>
                        <td>{{item.Sch_Name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #FireTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">開除學籍</h4>
        <button type="button" class="btn-close btn-close-white pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text">開除學年</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdFire.FireYear">
                        <option value="{{item}}" *ngFor="let item of Years"> {{item}}學年 </option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="input-group mb-3">
                    <span class="input-group-text">開除學期</span>
                    <select class="form-select shadow-none" [(ngModel)]="stdFire.FireTerm">
                        <option *ngFor="let item of term" value="{{item.itemValue}}">{{item.itemName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">            
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <span class="input-group-text">開除日期</span>
                    <input type="text" placeholder="點選設定" class="form-control pointer shadow-none" bsDatepicker
                        [bsConfig]="bsConfig" readonly [(ngModel)]="stdFire.FireDate"
                        style="background-color: transparent;">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-12">
                    <span class="input-group-text">開除文號</span>
                    <input type="text" class="form-control shadow-none" [(ngModel)]="stdFire.FireMoe">
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="buttonRowH40">
                <button class="save-btn-gray float-end me-3 mx-auto" (click)="fireStdData()">儲存</button>
            </div>
        </div>        
    </div>
</ng-template>
