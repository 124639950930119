import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GetRelNameList } from 'src/app/_Models/getRelNameList';
import { GetSTDStatusList } from 'src/app/_Models/getSTDStatusList';
import { FileExchangeService } from 'src/app/_Services/_Shared/file-exchange.service';
import { SystemService } from 'src/app/_Services/_Shared/system.service';
import { FileExchange } from 'src/app/_SharedFunctions/fileExcange';
import { environment } from 'src/assets/environments/environment';
import Swal from 'sweetalert2';
import { GetStdData,GetStdFire } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdData';
import { GetStdList } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdList';
import { GetStdListInputPage } from 'src/app/_Models/AD/ADSTD/ADSTD0201/getStdListInputPage';
import { GetEduList } from 'src/app/_Models/getEduList';
import { GetDeptList } from 'src/app/_Models/getDeptList';
import { GetClassList } from 'src/app/_Models/getClassList';
import { InitialData } from 'src/app/_SharedFunctions/initialData';
import { GetTeamList } from 'src/app/_Models/getTeamList';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AccountService } from 'src/app/_Services/_Shared/account.service';
import { GetSelectList } from 'src/app/_Models/getSelectList';
import { GetRedClassList } from 'src/app/_Models/AD/ADTFS/ADTFS0701/getRedClassList';
import { getSchoolClassList } from 'src/app/_Models/getSchoolTypeList';
import { Search } from 'src/app/_Models/AD/ADRGP/ADRGP0302/search';
import { TimeService } from 'src/app/_Services/_Shared/time.service';

@Component({
    selector: 'app-ADSTD0201',
    templateUrl: './ADSTD0201.component.html',
    styleUrls: ['./ADSTD0201.component.css']
})
export class ADSTD0201Component implements OnInit {
    baseUrl = environment.apiUrl;
    grade = require('src/app/_Models/_SelectInput/grade.json').grade;
    nowIndex = 0;
    hideForm = false;
    schIndex = 0;
    initialData = new InitialData(this.http);
    data!: Search[];
    newStdData: GetStdData = {
        DataKey: '',
        StdNo: '',
        EduNo: '',
        DeptNo: '',
        TeamNo: '',
        Grade: '',
        Class: '',
        ClassID: '',
        StdName: '',
        StdEngName: '',
        StdMonkName: '',
        Birthday: '',
        IDNo: '',
        PassPortNo: '',
        ResidenceNo: '',
        EntryTower: '',
        CNIP: '',
        Status: '',
        Blood: '',
        Sex: '',
        Marry: '',
        MilitaryService: '',
        OtherEmail: '',
        EMail: '',
        CountryID: '',
        Country: '',
        Capital: '',
        NomZip: '',
        NomCity: '',
        NomTown: '',
        VillageName: '',
        VillageType: '',
        NeighborHood: '',
        NomElseAddr: '',
        ComZip: '',
        ComCity: '',
        ComTown: '',
        ComAddr:'',
        ComElseAddr: '',
        NomTel: '',
        ComTel: '',
        MobileTel: '',
        EngAddress: '',
        EngTel: '',
        StdType: '',
        EntryID: '',
        ClassStatus: '',
        FieldType: '',
        EntryDuct: '',
        EntryDiv:'',
        ExpenseType: '',
        ExDate: '',
        AbDate: '',
        RedClassNo: '',
        IsSameDept: '',
        IsAddition: '',
        EntryYear: '',
        EntryMonth: '',
        EntryDay: '',
        EntryMoeNo: '',
        EntryMoeMemo: '',
        InYear: '',
        InTerm: '',
        InStatus: '',
        GradYear: '',
        GradMonth: '',
        GradDay: '',
        GradMoeNo: '',
        GradMoeMemo: '',
        EndYear: '',
        EndTerm: '',
        GradSchNo: '',
        ContrastYear: '',
        GradStatus: '',
        Memo: '',
        PIC: '',
        MonkType: '0',
        AliasEng: '',
        AbroadID: '',
        EntryNo: '',
        SpecialType: '',
        Form5Student: false,
        RecomUnit: '',
        ExchangeSchool: '',
        ScholarshipName: '',
        ScholarshipMoney: '',
        FireYear:'',
        FireTerm:'', 
        FireDate:'',
        FireMoe:'', 
        STD02: [],
        STD03: [],
        STD04: [],
        STD05: [],
        STD06: [],
        STD07: [],
        STD08: [],
        STD09: [],
    }

    model = {
        Years: '',
        Term: ''
    }

    getStdListInput!: GetStdListInputPage;

    sTDStatusList!: GetSTDStatusList[];

    // 新增學籍資料時用
    stdNewDeptList!: GetDeptList[];
    stdNewTeamList!: GetTeamList[];
    stdNewEduList!: GetEduList[];
    stdNewClassList!: GetClassList[];
    stdNewCityList!: GetSelectList[];
    stdNewNomTownList!: GetSelectList[];

    // 學生詳細資料時用
    stdDeptList!: GetDeptList[];
    stdEduList!: GetEduList[];
    stdClassList!: GetClassList[];
    stdTeamList!: GetTeamList[];

    modalRef!: BsModalRef;
    bsConfig = environment.bsDatePickerConfig;
    Years!: Array<string>;
    stdList!: GetStdList[];
    stdData!: GetStdData;
    stdFire = {DataKey:'',StdNo:'',FireYear:'',FireTerm:'',FireDate:'',FireMoe:''};
    open!: boolean;
    isFire:boolean=false;
    countryList!: GetSelectList[];

    cityList!: GetSelectList[];
    comTownList!: GetSelectList[];
    nomTownList!: GetSelectList[];
    relNameList!: GetRelNameList[];
    fileType!: GetSelectList[];
    entryDuctList!: GetSelectList[];
    entryIDList!: GetSelectList[];
    entryNewList!: GetSelectList[];
    entryDivList!: GetSelectList[];
    entrySpecialTypeList!: GetSelectList[];
    redClassList!: GetRedClassList[];
    schoolTypeList!: getSchoolClassList[];
    fileExchange = new FileExchange();
    IsEdit!: boolean;
    army = require('src/app/_Models/_SelectInput/army.json').army;
    marriage = require('src/app/_Models/_SelectInput/marriage.json').marriage;
    bloodType = require('src/app/_Models/_SelectInput/bloodType.json').bloodType;
    month = require('src/app/_Models/_SelectInput/month.json').month;
    term = require('src/app/_Models/_SelectInput/term.json').term;
    day = require('src/app/_Models/_SelectInput/day.json').day;
    ImgUrl!: SafeUrl;
    code = '';
    pagecount=environment.pagecount;
    entryMoeNoList!: GetSelectList[];
    gradMoeNoList!: GetSelectList[];
    graduateType!: GetSelectList[];
    constructor(private http: HttpClient,
        private toastr: ToastrService,
        private domSanitizer: DomSanitizer,
        private systemService: SystemService,
        private datePipe: DatePipe,
        private fileExchangeServices: FileExchangeService,
        private modalService: BsModalService,
        private accountService: AccountService,
        private timeService: TimeService) { }

    ngOnInit(): void {
        this.IsEdit = false;

        this.accountService.currentUser$.subscribe((response) => {
            if (response != null) {
                this.code = response.Code;
            }
        });

        this.timeService.getTimeCntr().subscribe((response) => {
            this.model.Years = response.Now_Years;
            this.model.Term = response.Now_Term;
        });
        this.setModel(this.getStdListInput);
        this.getRelNameList();
        this.getSTDStatusList();
        this.getCityList();
        this.getEntryDuct();
        this.getEntryIDList();
        this.getEntryNew();
        this.getEntrySpecialType();
        this.getEntryDiv();
        this.getRedClassList();
        this.getSchoolTyp();
        this.getGradMoeNo();
        this.getEduList()
        this.getEntryMoeNo();
        this.getFieldType();
        this.getSTDStatusList();
        this.getYears();
        this.getCountryList();
        this.getGraduateType();
        this.open = true;
    }

    getYears() {
        this.initialData.getYears().subscribe((response) => {
            if (response) {
                this.Years = response;
            }
        });
    }

    setModel(model: GetStdListInputPage) {
        this.stdData = {
            DataKey: '',
            StdNo: '',
            EduNo: '',
            DeptNo: '',
            TeamNo: '',
            Grade: '',
            Class: '',
            ClassID: '',
            StdName: '',
            StdEngName: '',
            StdMonkName: '',
            Birthday: '',
            IDNo: '',
            PassPortNo: '',
            ResidenceNo: '',
            EntryTower: '',
            CNIP: '',
            Status: '',
            Blood: '',
            Sex: '',
            Marry: '',
            MilitaryService: '',
            OtherEmail: '',
            EMail: '',
            CountryID: '',
            Country: '',
            Capital: '',
            NomZip: '',
            NomCity: '',
            NomTown: '',
            VillageName: '',
            VillageType: '',
            NeighborHood: '',
            NomElseAddr: '',
            ComZip: '',
            ComCity: '',
            ComTown: '',
            ComAddr:'',
            ComElseAddr: '',
            NomTel: '',
            ComTel: '',
            MobileTel: '',
            EngAddress: '',
            EngTel: '',
            StdType: '',
            EntryID: '',
            ClassStatus: '',
            FieldType: '',
            EntryDuct: '',
            EntryDiv:'',
            ExpenseType: '',
            ExDate: '',
            AbDate: '',
            RedClassNo: '',
            IsSameDept: '',
            IsAddition: '',
            EntryYear: '',
            EntryMonth: '',
            EntryDay: '',
            EntryMoeNo: '',
            EntryMoeMemo: '',
            InYear: '',
            InTerm: '',
            InStatus: '',
            GradYear: '',
            GradMonth: '',
            GradDay: '',
            GradMoeNo: '',
            GradMoeMemo: '',
            EndYear: '',
            EndTerm: '',
            GradSchNo: '',
            ContrastYear: '',
            GradStatus: '',
            Memo: '',
            PIC: '',
            MonkType: '0',
            AliasEng: '',
            AbroadID: '',
            EntryNo: '',
            SpecialType: '',
            Form5Student: false,
            RecomUnit: '',
            ExchangeSchool: '',
            ScholarshipName: '',
            ScholarshipMoney: '',
            FireYear:'',
            FireTerm:'', 
            FireDate:'',
            FireMoe:'',             
            STD02: [],
            STD03: [],
            STD04: [],
            STD05: [],
            STD06: [],
            STD07: [],
            STD08: [],
            STD09: [],
        };
        this.getStdListInput = model;
    }

    getFieldType() {
        this.systemService.getFieldType().subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.fileType = response;
        })
    }

    clearData() {
        this.IsEdit = false;
        this.open = true;
        this.stdData = {
            DataKey: '',
            StdNo: '',
            EduNo: '',
            DeptNo: '',
            TeamNo: '',
            Grade: '',
            Class: '',
            ClassID: '',
            StdName: '',
            StdEngName: '',
            StdMonkName: '',
            Birthday: '',
            IDNo: '',
            PassPortNo: '',
            ResidenceNo: '',
            EntryTower: '',
            CNIP: '',
            Status: '',
            Blood: '',
            Sex: '',
            Marry: '',
            MilitaryService: '',
            OtherEmail: '',
            EMail: '',
            CountryID: '',
            Country: '',
            Capital: '',
            NomZip: '',
            NomCity: '',
            NomTown: '',
            VillageName: '',
            VillageType: '',
            NeighborHood: '',
            NomElseAddr: '',
            ComZip: '',
            ComCity: '',
            ComTown: '',
            ComAddr:'',
            ComElseAddr: '',
            NomTel: '',
            ComTel: '',
            MobileTel: '',
            EngAddress: '',
            EngTel: '',
            StdType: '',
            EntryID: '',
            ClassStatus: '',
            FieldType: '',
            EntryDuct: '',
            EntryDiv:'',
            ExpenseType: '',
            ExDate: '',
            AbDate: '',
            RedClassNo: '',
            IsSameDept: '',
            IsAddition: '',
            EntryYear: '',
            EntryMonth: '',
            EntryDay: '',
            EntryMoeNo: '',
            EntryMoeMemo: '',
            InYear: '',
            InTerm: '',
            InStatus: '',
            GradYear: '',
            GradMonth: '',
            GradDay: '',
            GradMoeNo: '',
            GradMoeMemo: '',
            EndYear: '',
            EndTerm: '',
            GradSchNo: '',
            ContrastYear: '',
            GradStatus: '',
            Memo: '',
            PIC: '',
            MonkType: '0',
            AliasEng: '',
            AbroadID: '',
            EntryNo: '',
            SpecialType: '',
            Form5Student: false,
            RecomUnit: '',
            ExchangeSchool: '',
            ScholarshipName: '',
            ScholarshipMoney: '',
            FireYear:'',
            FireTerm:'', 
            FireDate:'',
            FireMoe:'',             
            STD02: [],
            STD03: [],
            STD04: [],
            STD05: [],
            STD06: [],
            STD07: [],
            STD08: [],
            STD09: [],
        };
    }

    setHideForm() {
        this.hideForm = !this.hideForm;
    }

    InnitiateGetEduList(eduNo: string, isNewData: boolean) {
        this.systemService.getEduList('3', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdEduList = response;//額外新增變數
            this.getDeptList();
        })
    }

    getEduList() {
        this.systemService.getEduList('3', this.model.Years, this.model.Term, 'Y').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.stdEduList = response;
        })
    }

    getDeptList() {
        this.systemService.getDeptList(this.stdData.EduNo, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.stdDeptList = response;
            if(!this.IsEdit){
                this.stdData.DeptNo = '';
                this.stdData.Class = '';
            }

            this.stdTeamList = [];
            this.stdClassList = [];

            this.getTeamList(this.getStdListInput.DeptNo, !this.IsEdit);
            this.getClassList(this.getStdListInput.EduNo, this.getStdListInput.DeptNo, !this.IsEdit);

        });
    }

    getTeamList(dept: string, isNewData: boolean) {
        this.systemService.getTeamList('', this.model.Years, this.model.Term, dept, '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            if (isNewData) {
                this.stdNewTeamList = response
            }
            else {
                this.stdTeamList = response;
            }
        });
    }

    getClassList(edu: string, dept: string, isNewData: boolean) {

        this.systemService.getClassList(edu, dept, '', this.model.Years, this.model.Term, 'Y', '').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            if (isNewData) {
                this.stdNewClassList = response
            }
            else {
                this.stdClassList = response;
            }
        })
    }

    getEntryMoeNo() {
        this.systemService.getEntryMoeNo().subscribe((response) => {
            this.entryMoeNoList = response;
        });
    }

    getGradMoeNo() {
        this.systemService.getGradMoeNo().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.gradMoeNoList = response;
        });
    }

    getSchoolTyp() {
        this.systemService.getSchoolClass().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.schoolTypeList = response;
        })
    }

    deleteStdData() {
        Swal.fire({
            title: '是否確定刪除學生學籍資料 ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_Delete", this.stdData).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("刪除成功")
                })
            }
            else if (result.isDenied) {
                this.toastr.info("已取消刪除。");
            }
        });
    }

    getStdList(page:number) {
        this.setModel(this.getStdListInput);
        if (this.getStdListInput == undefined) {
            this.toastr.info("未選取任何條件");
            return;
        }
        else {
            if(this.getStdListInput.StdNoFrom=='' && this.getStdListInput.StdNoTo==''){
                if (this.getStdListInput.Status == '') {
                    this.toastr.info("請至少選擇學生狀態");
                    return;
                }
            }

            this.getStdListInput.Page=page;
            this.getStdListInput.limit=environment.pagecount;
            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_List", this.getStdListInput).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    this.stdList = []
                    return;
                }
                this.stdList = response;
                this.getStdListInput.TotalPage=Math.ceil(response[1].TCount/environment.pagecount);
                this.getStdListInput.Count=response[1].TCount;
                this.getStdListInput.Page=this.getStdListInput.Page;
                this.stdList.forEach(element => {
                    element.Selected = false;
                })
            })
        }
    }

    pluspage(){
        if(this.getStdListInput.Count-(this.getStdListInput.limit*this.getStdListInput.Page)>0)
        {
            this.getStdListInput.Page=this.getStdListInput.Page+1;
            this.getStdList(this.getStdListInput.Page);
        }
    }

    minuspage(){
        if(this.getStdListInput.Page>1)
        {
            this.getStdListInput.Page=this.getStdListInput.Page-1;
            this.getStdList(this.getStdListInput.Page);
        }
    }

    getRedClassList() {
        this.systemService.getRedClassList().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.redClassList = response;
        })
    }

    getCountryList() {
        this.systemService.getCountryList().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.countryList = response;
        })
    }

    getCityList() {
        this.systemService.getCityList('000').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.cityList = response;
        })
    }

    getTownList(city: string, isNew: boolean) {
        this.systemService.getTownList('000', city).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            if (isNew) {
                this.stdNewNomTownList = response;
                return;
            }
            this.nomTownList = response;
        });
    }

    getGraduateType() {
        this.systemService.getGraduateType().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.graduateType = response;
        })
    }

    // 如果縣市有值，則觸發該縣市的區域下拉
    initiateTownList(nomCity: string) {
        this.systemService.getTownList('000', nomCity).subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.nomTownList = response;
        });

    }

    getRelNameList() {
        this.systemService.getRelNameList().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.relNameList = response;
        });
    }

    getSTDStatusList() {
        this.systemService.getSTDStatusList().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.sTDStatusList = response;
        })
    }

    getEntryDuct() {
        this.systemService.getEntryDuct().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entryDuctList = response;
        })
    }

    getEntryNew() {
        this.systemService.getEntryType().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entryNewList = response;
        })
    }

    getEntrySpecialType() {
        this.systemService.getEntrySpecialType().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entrySpecialTypeList = response;
        })
    }

    getEntryDiv() {
        this.systemService.getEntryDiv().subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entryDivList = response;
        })
    }

    getEntryIDList() {
        this.systemService.getEntryIDList('').subscribe((response) => {
            if (response.Info == false) {
                return;
            }
            this.entryIDList = response;
        })
    }

    setChecked(type: string, event: any) {

        let input = <HTMLInputElement>event.target;

        if (input.checked) {
            if (this.stdData) {
                if (type == 'Instatus') {
                    this.stdData.InStatus = '';
                }

                if (type == 'GradStatus') {
                    this.stdData.GradStatus = '0';
                }

                if (type == 'IsAddition') {
                    this.stdData.IsAddition = '0';
                }

                if (type == 'IsSameDept') {
                    this.stdData.IsSameDept = '';
                }
            }

        }
        else {
            if (this.stdData) {
                if (type == 'Instatus') {
                    this.stdData.InStatus = 'Y';
                }

                if (type == 'GradStatus') {
                    this.stdData.GradStatus = '1';
                }

                if (type == 'IsAddition') {
                    this.stdData.IsAddition = '1';
                }

                if (type == 'IsSameDept') {
                    this.stdData.IsSameDept = 'Y';
                }
            }
        }
    }

    getStdData(dataKey: string, index: number) {
        this.IsEdit = true;
        this.open = false;
        this.isFire = false;
        this.nowIndex = index
        this.stdList.forEach(element => {
            element.Selected = false;
        })
        this.stdList[index].Selected = true;
        this.stdFire = {DataKey:'',StdNo:'',FireYear:'',FireTerm:'',FireDate:'',FireMoe:''};

        this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_Detail", { DataKey: dataKey }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.stdData = response;

            if (response.PIC !== null) {
                this.ImgUrl = this.domSanitizer.bypassSecurityTrustUrl(response.PIC);
            }

            if (this.stdData) {
                // 為了抓到學生資料，下拉式選單在這裡啟動
                this.InnitiateGetEduList(this.stdData.EduNo, false);
                this.initiateTownList(this.stdData.NomCity);
                this.getCityList();
                this.stdData.PIC = '';
                this.stdFire.DataKey=this.stdData.DataKey;
                this.stdFire.StdNo=this.stdData.StdNo;                
                if(this.stdData.Status=='開除'){
                    this.isFire = true;
                    this.stdFire.FireYear=this.stdData.FireYear;
                    this.stdFire.FireTerm=this.stdData.FireTerm;
                    this.stdFire.FireDate=this.stdData.FireDate;
                    this.stdFire.FireMoe=this.stdData.FireMoe;    
                }            
            }

        });
    }

    importInputCkick() {
        document.getElementById('img')?.click();
    }

    importData($event: any) {
        let file = $event.target.files[0];
        if (!file) return;
        this.fileExchange.FileToBase64(this, file, this.GetBase64, '', '')
    }

    GetBase64(that: any, base64: string, file: any, dataKey: string, type: string) {
        Swal.fire({
            title: '確定上傳 ' + file.name + ' ?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: '是',
            denyButtonText: '否',
        }).then((result) => {
            if (result.isConfirmed) {
                that.stdData.PIC = base64;
                that.ImgUrl = that.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + base64);
            }
            else if (result.isDenied) {
                document.getElementById('img')?.setAttribute('value', '');
                that.toastr.info("已取消上傳。");
            }
        });
    }

    deleteData(type: string) {

        if (this.stdData) {
            if (type == 'family') {
                this.stdData.STD03.pop();
            }

            if (type == 'school') {
                this.stdData.STD02.pop();
            }
        }
    }

    addData(type: string) {
        let date = new Date();

        if (type == 'family') {

            if (this.stdData) {
                if (this.stdData.STD03 == null) {
                    this.stdData.STD03 = [];
                }

                this.stdData.STD03.push({
                    Birthday: '',
                    Com_Address: '',
                    EMail:'',
                    Com_Tel: '',
                    Com_Zip: '',
                    Dep_ID: '',
                    Dep_Name: '',
                    I_Num: '',
                    Mark: '',
                    Memo: '',
                    Occ_No: '',
                    Rel_Name: '',
                    Rel_No: '',
                    Std_No: this.stdData.StdNo,
                    Update_Date: (this.datePipe.transform(date, 'yyyy-MM-dd') || '').toString(),
                    Update_User: this.stdData.StdNo,
                    checkedTag: false
                });
            }
        }
        if (type == 'school') {

            if (this.stdData) {
                if (this.stdData.STD02 == null) {
                    this.stdData.STD02 = [];
                }

                this.stdData.STD02.push({
                    ClassSub: '',
                    Dept_Name: '',
                    Grad_Month: '',
                    Grad_Type: '',
                    Grad_Year: '',
                    I_Num: '',
                    Mark: '',
                    SchClass: '',
                    SchClass_Sub: '',
                    Sch_Name: '',
                    Sch_No: '',
                    Std_No: this.stdData.StdNo,
                    Turn_In: '',
                    Update_Date: (this.datePipe.transform(date, 'yyyy-MM-dd') || '').toString(),
                    Update_User: this.code
                });
            }
        }

    }

    fireData(template: TemplateRef<any>){
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-l'
        };

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    fireStdData(){
        if(this.stdData==null){
            this.toastr.info("未點選學生!");
            return;
        }

        if(this.stdData.DataKey==''){
            this.toastr.info("未點選學生!");
            return;
        }        

        Swal.fire({
            title: `確定將此學生設定為開除學籍?`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.stdData.Status='開除';

                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_Fire", this.stdFire).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("開除學籍成功");
                    this.getStdData(this.stdFire.DataKey,this.nowIndex);
                })
            } else if (result.isDenied) {
                this.toastr.info("動作已取消");
            }
        })
    }

    saveStdData(isNew: boolean) {
        //疑問為何傳空值
        if (isNew && this.checkData(this.newStdData)) {
            this.newStdData.Status = "在學";
            this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_Modify", this.newStdData).subscribe((response) => {
                if (response.Info == false) {
                    this.toastr.warning(response.Message);
                    return;
                }
                this.toastr.success("儲存成功");
            })
            return;
        }
        else {
            if (this.stdData) {
                if (this.checkData(this.stdData)) {
                    let _modifyAction=(this.stdData.Status=='開除')?'Get_Std_Fire':'Get_Std_Modify';

                    if(this.stdData.Status=='開除'){
                        this.stdFire.DataKey=this.stdData.DataKey;
                        this.stdFire.StdNo=this.stdData.StdNo;
                        this.stdFire.FireYear=this.stdData.FireYear;
                        this.stdFire.FireTerm=this.stdData.FireTerm;
                        this.stdFire.FireDate=this.stdData.FireDate;
                        this.stdFire.FireMoe=this.stdData.FireMoe;
                    }

                    this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/"+_modifyAction, (this.stdData.Status=='開除')?this.stdFire:this.stdData).subscribe((response) => {
                        if (response.Info == false) {
                            this.toastr.warning(response.Message);
                            return;
                        }
                        this.toastr.success("儲存成功");
                    })
                }
            }
        }

    }

    openModel(type: string, template: TemplateRef<any>, index: number) {
        let modalConfig = {
            backdrop: true,
            ignoreBackdropClick: true,
            class: 'modal-xl'
        };
        if (type == 'sch') {
            this.schIndex = index;
            this.modalRef = this.modalService.show(template, modalConfig);
            return;
        }
        this.InnitiateGetEduList('', true);
        this.getCityList();

        this.modalRef = this.modalService.show(template, modalConfig);
    }

    checkData(data: GetStdData) {
        if (data.EduNo == '' || data.DeptNo == '' || data.Class == '' || data.TeamNo == '' || data.Grade == '' || data.StdNo == '') {
            this.toastr.info("請填寫學籍資料");
            return false;
        }

        if (data.StdName == '' || data.IDNo == '' || data.Sex == '' || data.Birthday == '' || data.CountryID == '') {
            this.toastr.info("請填寫基本資料");
            return false;
        }

        if(data.Country=='000'){
            if (data.NomZip == '' || data.NomCity == '' || data.NomTown == '' || data.VillageName == '???' || data.VillageType == '???' || data.NeighborHood == '???' || data.NomElseAddr == '') {
                this.toastr.info("請填寫戶籍資料");
                return false;
            }
        }

        if (data.EntryID == '' || data.ClassStatus == '') {
            this.toastr.info("請填寫入學身份及編級狀態");
            return false;
        }

        if (data.EntryYear == '' || data.EntryMonth == '' || data.EntryDay == '' || data.InYear == '' || data.InTerm == '') {
            this.toastr.info("請填寫入學資料");
            return false;
        }

        return true;
    }

    search(SchClass: string, Search_Word: string) {
        this.http.post<any>(this.baseUrl + "ACAD/RGP/ADRGP0302/Search", {
            Country: '',
            City: '',
            SchClass: SchClass,
            Search_Word: Search_Word
        }).subscribe((response) => {
            if (response.Info == false) {
                this.toastr.warning(response.Message);
                return;
            }
            this.data = response;
        })
    }

    setSchool(Sch_Name: string) {
        if (this.stdData) {
            this.stdData.STD02[this.schIndex].Sch_Name = Sch_Name;
            this.modalRef.hide();
            this.schIndex = 0;
        }

    }

    SaveMaster(){
        Swal.fire({
            title: `是否確定異動[中文名]、[性別]、[出生日]與[身分證]資料?`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.http.post<any>(this.baseUrl + "ACAD/STD/ADSTD02_01/Get_Std_SaveMaster", {DataKey:this.stdData.DataKey,StdNo:this.stdData.StdNo,StdName:this.stdData.StdName,IDNo:this.stdData.IDNo,Sex:this.stdData.Sex,Birthday:this.stdData.Birthday}).subscribe((response) => {
                    if (response.Info == false) {
                        this.toastr.warning(response.Message);
                        return;
                    }
                    this.toastr.success("資料異動成功");
                })
            } else if (result.isDenied) {
                this.toastr.info("動作已取消");
            }
        })        
    }
}
